//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable } from '@/components'
import { flowableInstanceTaskPage } from '@/api/modular/flowable/instanceTaskManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

export default {
  components: {
    STable
  },

  data () {
    return {
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '任務名稱',
          dataIndex: 'name'
        },
        {
          title: '辦理人',
          dataIndex: 'assigneeInfo'
        },
        {
          title: '創建時間',
          dataIndex: 'formatCreateTime',
          scopedSlots: { customRender: 'formatCreateTime' }
        },
        {
          title: '辦理時間',
          dataIndex: 'formatEndTime',
          scopedSlots: { customRender: 'formatEndTime' }
        },
        {
          title: '是否辦理',
          dataIndex: 'ended',
          scopedSlots: { customRender: 'ended' }
        },
        {
          title: '任務歷時',
          dataIndex: 'duration'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        this.queryParam.processInstanceId = this.processInstanceId
        if (this.processInstanceId === '') {
           return ''
        } else {
          return flowableInstanceTaskPage(Object.assign(parameter, this.queryParam)).then((res) => {
            return res.data
          })
        }
      },
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      endedDictTypeDropDown: [],
      processInstanceId: ''
  }
  },
  created () {
  },

  methods: {
    /**
     * 打開此頁面
     */
    openInstanceTask (record) {
      this.processInstanceId = record.id
      this.$refs.table.refresh(true)
      this.sysDictTypeDropDown()
    },

    returnPage () {
      this.$emit('ok')
    },

    endedFilter (ended) {
      // eslint-disable-next-line eqeqeq
      const values = this.endedDictTypeDropDown.filter(item => item.code == ended.toString())
      if (values.length > 0) {
        return values[0].value
      }
    },

    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'ended_status' }).then((res) => {
        this.endedDictTypeDropDown = res.data
      })
    },

    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }

}
